import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'
import dayjs from 'dayjs'

const log = new Logger('actions/notifications')
const storeKey = 'notifications'

/**
 * Adds notifications to the store, ensuring no duplicates by ID.
 * @param {Object|Array} notifications - A single notification object or an array of notifications.
 */
export const addNotificationsToStore = notifications => {
    const notificationsArray = Array.isArray(notifications) ? notifications : [notifications]
    const currentNotifications = store.notifications.getValue()
    
    const newNotifications = notificationsArray.filter(
        newNotif =>
            !currentNotifications.some(
                existingNotif => existingNotif.message.data.id === newNotif.message.data.id,
            ),
    )
    
    const allNotifications = [...currentNotifications, ...newNotifications]
    
    const sortedNotifications = allNotifications.sort((a, b) => {
        const dateA = dayjs(a.message.data.createdAt || a.message.timestamp || 0).valueOf()
        const dateB = dayjs(b.message.data.createdAt || b.message.timestamp || 0).valueOf()
        
        return dateB - dateA
    })
    
    store.notifications.setValue(sortedNotifications)
}

/**
 * Transforms a notification object to include a channel name for grouping.
 * @param {Object} notification - The raw notification object.
 * @returns {Object} - The transformed notification object.
 */
const _transformNotification = notification => {
    const channelName =
        notification.target === 'user'
            ? `users:${notification.userId}`
            : `organizations:${notification.organizationId}`
    
    return {
        channelName,
        message: {
            data: notification,
        },
    }
}

/**
 * Fetches all notifications from the server and updates the store.
 */
export const fetchNotifications = async () => {
    try {
        const res = await ParseAPI.get('notifications')
        const notifications = res.data.map(el => _transformNotification(el))
        
        addNotificationsToStore(notifications)
    } catch (err) {
        log.e('fetchNotifications', err)
    }
}

/**
 * Fetches top notifications by type from the server and updates the store.
 */
export const fetchTopNotificationsByType = async () => {
    try {
        const res = await ParseAPI.get('notifications/top')
        const notifications = res.data.map(el => _transformNotification(el))
        
        store.notifications.setValue(notifications)
    } catch (err) {
        log.e('fetchTopNotificationsByType', err)
    }
}

/**
 * Marks a specific notification as seenByTarget.
 * Updates both the in-memory store and the database.
 * @param {string} id - The ID of the notification to mark as seen.
 */
export const markNotificationAsSeen = async id => {
    const currentNotifications = store.notifications.getValue()
    
    const updatedNotifications = currentNotifications.map(notification =>
        notification.message.data.id === id
            ? {
                ...notification,
                message: {
                    ...notification.message,
                    data: {
                        ...notification.message.data,
                        seenLocally: true,
                    },
                },
            }
            : notification,
    )
    
    store.notifications.setValue(updatedNotifications)
    
    try {
        await ParseAPI.put(`notifications/seen/${id}`, { seenByTarget: true })
    } catch (error) {
        log.e('markNotificationAsSeen', error)
    }
}

/**
 * Dismisses a specific notification.
 * Removes it from the in-memory store and updates the database.
 * @param {string} id - The ID of the notification to dismiss.
 * @param {string} type - The type of the notification to dismiss.
 */
export const dismissNotification = async (id, type) => {
    store.arrayDeleteById(storeKey, id, it => it.message.data.id)
    
    try {
        await ParseAPI.put(`notifications/dismiss/${id}`, { type })
    } catch (error) {
        log.e('dismissNotification', error)
    }
}

/**
 * Dismisses all visible notifications in the store.
 * Clears the in-memory store and updates the database.
 */
export const dismissAllVisibleNotifications = async () => {
    const currentNotifications = store.notifications.getValue()
    const notificationIds = currentNotifications.map(
        notification => notification.message.data.id,
    )
    
    store.notifications.setValue([])
    
    try {
        await ParseAPI.post('notifications/dismiss', { ids: notificationIds })
    } catch (error) {
        log.e('dismissAllVisibleNotifications', error)
    }
}
