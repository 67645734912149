
// Organization-exclusive statuses
export const ORG_DOCUMENT_STATUS_PROCESSING = 'processing'                 // Document processing
export const ORG_DOCUMENT_STATUS_OCR_APPLIED = 'ocr_applied'               // OCR ran on document
export const ORG_DOCUMENT_STATUS_MODEL_APPLIED = 'model_applied'           // ML model ran on document
export const ORG_DOCUMENT_STATUS_READY_FOR_REVIEW = 'ready_for_review'     // Document may have been reviewed previously
export const ORG_DOCUMENT_STATUS_REVIEW_IN_PROGRESS = 'review_in_progress' // A human is reviewing this document
export const ORG_DOCUMENT_STATUS_REVIEWED = 'reviewed'                     // Annotations reviewed by a human
export const ORG_DOCUMENT_STATUS_FAILED = 'failed'                         // Failed when applying model

export const ORG_DOCUMENT_STATUSES = [
    
    ORG_DOCUMENT_STATUS_PROCESSING,
    ORG_DOCUMENT_STATUS_OCR_APPLIED,
    ORG_DOCUMENT_STATUS_MODEL_APPLIED,
    ORG_DOCUMENT_STATUS_FAILED,
    
    ORG_DOCUMENT_STATUS_READY_FOR_REVIEW,
    ORG_DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    ORG_DOCUMENT_STATUS_REVIEWED,
    
]

export const DOCUMENT_STATUS_UPLOADED = 'uploaded'                 // Document uploaded
export const DOCUMENT_STATUS_SAVED = 'saved'                       // Page images created, ready to process
export const DOCUMENT_STATUS_PROCESSING = 'processing'             // Document processing
export const DOCUMENT_STATUS_OCR_APPLIED = 'ocr_applied'           // OCR ran on document
export const DOCUMENT_STATUS_MODEL_APPLIED = 'model_applied'       // ML model ran on document
export const DOCUMENT_STATUS_READY_FOR_REVIEW = 'ready_for_review' // Document may have been reviewed previously
export const DOCUMENT_STATUS_REVIEW_IN_PROGRESS = 'review_in_progress' // A human is reviewing this document
export const DOCUMENT_STATUS_REVIEWED = 'reviewed'                     // Annotations reviewed by a human

export const DOCUMENT_STATUS_FAILED_PREPROCESS = 'failed_preprocess'   // Document failed when page splitting
export const DOCUMENT_STATUS_FAILED = 'failed' // General failure (may have more specific ones later)

// All document statuses
export const DOCUMENT_STATUSES = [
    DOCUMENT_STATUS_UPLOADED,
    DOCUMENT_STATUS_SAVED,
    DOCUMENT_STATUS_PROCESSING,
    DOCUMENT_STATUS_OCR_APPLIED,
    DOCUMENT_STATUS_MODEL_APPLIED,
    
    DOCUMENT_STATUS_READY_FOR_REVIEW,
    DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    DOCUMENT_STATUS_REVIEWED,
    
    DOCUMENT_STATUS_FAILED_PREPROCESS,
    DOCUMENT_STATUS_FAILED,
]

// Documents that can have their status "reset"
export const DOC_CAN_BE_RESET_STATUSES = [
    DOCUMENT_STATUS_PROCESSING,
    DOCUMENT_STATUS_OCR_APPLIED,
    DOCUMENT_STATUS_MODEL_APPLIED,
    DOCUMENT_STATUS_READY_FOR_REVIEW,
    DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    DOCUMENT_STATUS_REVIEWED,
]

// Documents not ready to be processed yet
export const DOC_NOT_READY_STATUSES = [
    DOCUMENT_STATUS_UPLOADED,
    DOCUMENT_STATUS_PROCESSING,
    DOCUMENT_STATUS_OCR_APPLIED,
]

// Documents ready to be processed
export const DOC_READY_STATUSES = [
    DOCUMENT_STATUS_SAVED,
    DOCUMENT_STATUS_MODEL_APPLIED,
    DOCUMENT_STATUS_READY_FOR_REVIEW,
    DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    DOCUMENT_STATUS_REVIEWED,
]

// Documents that have not been reviewed/annotated yet
export const DOC_NOT_REVIEWED_STATUSES = [
    DOCUMENT_STATUS_UPLOADED,
    DOCUMENT_STATUS_SAVED,
    DOCUMENT_STATUS_PROCESSING,
    DOCUMENT_STATUS_OCR_APPLIED,
    DOCUMENT_STATUS_MODEL_APPLIED,
    DOCUMENT_STATUS_READY_FOR_REVIEW,
    
    DOCUMENT_STATUS_FAILED_PREPROCESS,
]

// Documents with in-progress review(s)
export const DOC_REVIEW_IN_PROGRESS_STATUSES = [
    DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
]

// Documents that have finished reviews/annotations
export const DOC_REVIEWED_STATUSES = [
    DOCUMENT_STATUS_REVIEWED,
]

export const DOC_CAN_PROCESS_STATUSES = [
    DOCUMENT_STATUS_SAVED,
    DOCUMENT_STATUS_FAILED,
    // DOCUMENT_STATUS_OCR_APPLIED, -- disabled for now because on this step processing button shouldn't appear.
    // ocr_applied and model_applied work together in this
    // DOCUMENT_STATUS_UPLOADED, -- disabled for now because calling page splitting twice causes it to resplit the doc
]

export const ORG_DOCS_CAN_PROCESS_STATUSES = [
    ORG_DOCUMENT_STATUS_FAILED,
]

export const DOC_FAILED_STATUSES = [
    DOCUMENT_STATUS_FAILED,
]

export const DOC_PROCESS_RUNSHEET_STATUSES = [
    ORG_DOCUMENT_STATUS_READY_FOR_REVIEW,
    ORG_DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    ORG_DOCUMENT_STATUS_REVIEWED,
    ORG_DOCUMENT_STATUS_FAILED,
]

export const SEARCH_HEADERS = [
    { id: 'name', label: 'Name', sortable: true },
    { id: 'grantors', label: 'Grantor', sortable: false },
    { id: 'grantees', label: 'Grantee', sortable: false },
    { id: 'bookVolume', label: 'Book Volume', sortable: true },
    { id: 'bookPage', label: 'Book Page', sortable: true },
    { id: 'bookType', label: 'Book Type', sortable: true },
    { id: 'instrumentNumber', label: 'Instr. #', sortable: true },
    { id: 'instrumentType', label: 'Instr. Type', sortable: true },
    { id: 'instrumentDate', label: 'Instr. Date', sortable: true },
    { id: 'fileDate', label: 'File Date', sortable: true },
    { id: 'recordingDate', label: 'Recording Date', sortable: true },
    { id: 'allLegalDescriptions', label: 'Legal Description', sortable: true },
]

export const HIDE_COL_ON_INDEX = ['recordingDate']

export const hasNoFile = document => !document.fileName || document.fileName === 'NAN'

export const DOCUMENT_TYPES = [
    { id: 'affidavit', label: 'Affidavit' },
    { id: 'assignment', label: 'Assignment' },
    { id: 'assignmentOfFinancingStatement', label: 'Assignment of Financing Statement' },
    { id: 'assignmentOfMortgage', label: 'Assignment of Mortgage' },
    { id: 'assignmentOfRentsAndLeases', label: 'Assignment of Rents and Leases' },
    { id: 'assignmentOfTaxCertificate', label: 'Assignment of Tax Certificate' },
    { id: 'assumptionOfMortgage', label: 'Assumption of Mortgage' },
    { id: 'certificate', label: 'Certificate' },
    { id: 'condoAmendment', label: 'Condo Amendment' },
    { id: 'condoMasterDeed', label: 'Condo Master Deed' },
    { id: 'condominiumLien', label: 'Condominium Lien' },
    { id: 'constructionLien', label: 'Construction Lien' },
    { id: 'continuationOfFinancingStatement', label: 'Continuation of Financing Statement' },
    { id: 'conveyance', label: 'Conveyance' },
    { id: 'courtCase', label: 'Court Case' },
    { id: 'declaration', label: 'Declaration' },
    { id: 'deed', label: 'Deed' },
    { id: 'easement', label: 'Easement' },
    { id: 'federalTaxLien', label: 'Federal Tax Lien' },
    { id: 'financingStatement', label: 'Financing Statement' },
    { id: 'judgment', label: 'Judgment' },
    { id: 'lease', label: 'Lease' },
    { id: 'lien', label: 'Lien' },
    { id: 'lisPendens', label: 'Lis Pendens' },
    { id: 'miscellaneous', label: 'Miscellaneous' },
    { id: 'modificationOfMortgage', label: 'Modification of Mortgage' },
    { id: 'mortgage', label: 'Mortgage' },
    { id: 'notice', label: 'Notice' },
    { id: 'order', label: 'Order' },
    { id: 'other', label: 'Other' },
    { id: 'plat', label: 'Plat' },
    { id: 'pudAmendment', label: 'PUD Amendment' },
    { id: 'pudMasterDeed', label: 'PUD Master Deed' },
    { id: 'releaseOfMortgage', label: 'Release of Mortgage' },
    { id: 'rerecordedDeed', label: 'Rerecorded Deed' },
    { id: 'rerecordedMortgage', label: 'Rerecorded Mortgage' },
    { id: 'restriction', label: 'Restriction' },
    { id: 'subordinationOfMortgage', label: 'Subordination of Mortgage' },
    { id: 'taxCertificate', label: 'Tax Certificate' },
    { id: 'will', label: 'Will' },
]

export const DOCUMENT_TYPES_MAP = {
    affidavit: 'Affidavit',
    assignment: 'Assignment',
    assignmentOfFinancingStatement: 'Assignment of Financing Statement',
    assignmentOfMortgage: 'Assignment of Mortgage',
    assignmentOfRentsAndLeases: 'Assignment of Rents and Leases',
    assignmentOfTaxCertificate: 'Assignment of Tax Certificate',
    assumptionOfMortgage: 'Assumption of Mortgage',
    certificate: 'Certificate',
    condoAmendment: 'Condo Amendment',
    condoMasterDeed: 'Condo Master Deed',
    condominiumLien: 'Condominium Lien',
    constructionLien: 'Construction Lien',
    continuationOfFinancingStatement: 'Continuation of Financing Statement',
    conveyance: 'Conveyance',
    courtCase: 'Court Case',
    declaration: 'Declaration',
    deed: 'Deed',
    easement: 'Easement',
    federalTaxLien: 'Federal Tax Lien',
    financingStatement: 'Financing Statement',
    judgment: 'Judgment',
    lease: 'Lease',
    lien: 'Lien',
    lisPendens: 'Lis Pendens',
    miscellaneous: 'Miscellaneous',
    modificationOfMortgage: 'Modification of Mortgage',
    mortgage: 'Mortgage',
    notice: 'Notice',
    order: 'Order',
    other: 'Other',
    plat: 'Plat',
    pudAmendment: 'PUD Amendment',
    pudMasterDeed: 'PUD Master Deed',
    releaseOfMortgage: 'Release of Mortgage',
    rerecordedDeed: 'Rerecorded Deed',
    rerecordedMortgage: 'Rerecorded Mortgage',
    restriction: 'Restriction',
    subordinationOfMortgage: 'Subordination of Mortgage',
    taxCertificate: 'Tax Certificate',
    will: 'Will',
}
