import * as store from '$store'
import { useEffect, useMemo, useState } from 'react'
import * as actions from '$actions'
import { useWireValue } from '@forminator/react-wire'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'



const PresetEvaluationResult = () => {
    
    const params = useParams()
    
    const preset = useWireValue(store.preset)
    
    const [evalRunsheets, setEvalRunsheets] = useState([])
    const [evalCollections, setEvalCollections] = useState([])
    
    const [selectedDatasetId, setSelectedDatasetId] = useState(null)
    const [selectedBaselinePresetId, setSelectedBaselinePresetId] = useState(null)
    
    const [baselineOptions, setBaselineOptions] = useState([])
    const [baselineChecked, setBaselineChecked] = useState(true)
    
    const [baselineEvaluations, setBaselineEvaluations] = useState([])
    const [selectedPresetEvaluations, setSelectedPresetEvaluations] = useState([])
    
    const [showRawDataModal, setShowRawDataModal] = useState(false)
    const [rawData, setRawData] = useState(null)
    
    const [selectedMetricName, setSelectedMetricName] = useState(null)
    
    const isChainer = useMemo(() => preset?.system === 'chainer', [preset])
    const [isEvaluating, setIsEvaluating] = useState(false)
    
    const combinedMetrics = [...selectedPresetEvaluations, 
        ...baselineEvaluations].reduce((uniqueArray, currentItem) => {
        const existingItem = uniqueArray.find(
            item => item.metricName === currentItem.metricName && item.category === currentItem.category,
        )
        
        if (existingItem) {
            existingItem.otherMetricValue = currentItem.metricValue
            existingItem.otherRawResults = currentItem.rawResults
            existingItem.metricName = currentItem.metricName
        } else {
            uniqueArray.push(currentItem)
        }
        
        return uniqueArray
    }, [])
    
    const presetValues = combinedMetrics.map(k => {
        const val = selectedPresetEvaluations.find(l => (l.metricName === k.metricName && l.category === k.category))
        
        const final = {
            value: val || '-',
            otherMetricValue: k.otherMetricValue ? parseFloat(k.otherMetricValue) : '-',
            originalMetricValue: val?.metricValue ? parseFloat(val?.metricValue) : '-',
        }
        
        return final
    })
    
    const baselineValues = combinedMetrics.map(k => {
        const val = baselineEvaluations.find(l => (l.metricName === k.metricName && l.category === k.category))
        
        const final = { value: val || '-' }
        
        return final
    })
    
    const fetchBaselinePresetData = async baselinePresetId => {
        
        setSelectedBaselinePresetId(baselinePresetId)
        
        if (!baselinePresetId)
            return setBaselineEvaluations([])
        
        const evaluation = await actions.getEvalFromPreset(baselinePresetId,
            selectedDatasetId, isChainer)
        
        setBaselineEvaluations(evaluation)
    }
    
    const fetchPresetEvaluations = async datasetId => {
        
        setSelectedDatasetId(datasetId)
        
        if (!datasetId) {
            setBaselineOptions([])
            setSelectedPresetEvaluations([])
            return
        }
        
        
        const baselineOpts = await actions.getPresetsFromDataset(datasetId, preset.system)
        
        setBaselineOptions(baselineOpts)
        
        const evaluations = await actions.getEvalFromPreset(preset.id, datasetId, isChainer)
        
        setSelectedPresetEvaluations(evaluations)
        
    }
    
    const evaluatePreset = async () => {
        
        await actions.createEval(preset.id,
            !isChainer ? selectedDatasetId : undefined, // collectionId being the datasetId
            isChainer ? selectedDatasetId : undefined) // runsheetId being the datasetId
        
        toast.success(`Evaluation started for "${preset.name}".`)
        
    }
    
    const checkIfEvaluationWasCompleted = async () => {
        
        const intervalid = setInterval(() => {
            
            actions.checkIfEvaluationWasCompleted(preset.id).then(ev => {
                if (ev) {
                    
                    actions.getEvalFromPreset(preset.id, selectedDatasetId, isChainer)
                        .then(e => setSelectedPresetEvaluations(e))
                    
                    setIsEvaluating(false)
                    
                    clearInterval(intervalid)
                }
            })
            
        }, 5000)
        
    }
    
    useEffect(() => {
        
        if (!params?.id) return
        
        actions.findPresetById(params.id)
        
    }, [params.id])
    
    useEffect(() => {
        
        if (isChainer) {
            
            actions.getEvalRunsheets().then(run => {
                setEvalRunsheets(run)
            })
            
        } else {
            
            actions.getEvalCollections().then(col => {
                setEvalCollections(col)
            })
            
        }
        
    }, [isChainer])
    
    useEffect(() => {
        
        
        
    }, [selectedDatasetId, preset])
    
    return (
        
        <div className='p-4 w-full'>
            <div>
                <h3>
                    {`Evaluation - ${preset?.name}`}
                </h3>
                <div className='flex flex-row gap-2'>
                    <p>
                        {preset?.system}
                    </p>
                    <p> - </p>
                    <p>
                        {preset?.vet?.name}
                    </p>
                </div>
            </div>
            
            <div className='pt-12 flex justify-between'>
                <div className='w-4/12 flex content-center gap-2'>
                    <div className='flex flex-col gap-2 flex-1'>
                        <label htmlFor="dataset" className='self-center'>
                            Dataset
                        </label>
                        <select
                            id="dataset"
                            className="select select-bordered select-sm"
                            value={selectedDatasetId}
                            onChange={e => fetchPresetEvaluations(e.target.value) }>
                            <option value=""></option>
                            {(preset.system === 'chainer' ? evalRunsheets : evalCollections).map(it => (
                                <option key={it.id} value={it.id}> 
                                    {it.name}
                                </option>
                            ))}
                        
                        </select>
                    </div>
                    <div className='content-center pt-8 flex flex-row gap-2'>
                        <button
                            className="btn btn-primary"
                            disabled={!selectedDatasetId}
                            onClick={() => {
                                setIsEvaluating(true)
                                evaluatePreset()
                                checkIfEvaluationWasCompleted()
                            }}>
                            {selectedPresetEvaluations.length ? 'Re-Evaluate' : 'Evaluate'}
                        </button>
                        {isEvaluating && (
                            <div className='flex flex-row gap-2'>
                                <p 
                                    className='text-blue-600 text-md font-thin self-center '> Evaluating 
                                </p>
                                <span className='loading loading-spinner loading-xs text-blue-600' /> 
                            </div>
                            
                        )}
                    </div>
                </div>
                
                <div className='flex flex-col gap-2 w-4/12'>
                    <label htmlFor="baseline" className='self-center'>
                        Baseline
                    </label>
                    <div className='flex gap-2'>
                        <input
                            type='checkbox'
                            className='checkbox self-center'
                            disabled={!selectedDatasetId}
                            onChange={() => setBaselineChecked(!baselineChecked)}
                            checked={baselineChecked && selectedBaselinePresetId}/>
                        <select
                            id="baseline"
                            className="select select-bordered select-sm w-full"
                            onChange={e => fetchBaselinePresetData(e.target.value)}
                            disabled={!selectedDatasetId}>
                            <option value=""></option>
                            {baselineOptions.map(it => (
                                <option key={it.id} value={it.id}> 
                                    {it.name}    
                                </option>
                            ))}
                        </select>
                    
                    </div>
                </div>
            
            </div>
            
            <div className='mt-12'>
                <div>
                    <div className='flex justify-center p-4 rounded-box'>
                        <div className='w-full'>
                            <h4 className='text-center'>
                                Metric
                            </h4>
                            <ul className='list-none flex flex-col'>
                                {combinedMetrics.map(k => 
                                    <li 
                                        className='border text-center content-center h-auto cursor-pointer' 
                                        onClick={() => {
                                            setShowRawDataModal(true)
                                            setSelectedMetricName(k.metricName)
                                            setRawData([k.otherRawResults, k.rawResults])
                                        }}>
                                        <div 
                                            className='h-8 content-center'> {k.metricName} - 
                                            <span className='font-bold'> {k.category || 'llm_score'} </span> 
                                        </div>                                       
                                    </li>,
                                )}
                            </ul>
                        </div>
                        <div className='w-full'>
                            <h4 className='text-center'>
                                Baseline
                            </h4>
                            <ul className={cn({
                                'hidden': !baselineChecked,
                            }, 'list-none flex flex-col ')}>
                                {baselineValues?.map(be => (
                                    <li key={be.id} className='border text-center content-center'>
                                        <div className='h-8 content-center'> {be.value.metricValue || be.value} </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='w-full'>
                            <h4 className='text-center'>
                                Result
                            </h4>
                            <ul  className='list-none flex flex-col '>
                                {presetValues.map(spe => (
                                    <li key={spe.id} className={cn('border text-center content-center', {
                                        'bg-green-600': spe.originalMetricValue > spe.otherMetricValue,
                                        'bg-red-600': spe.originalMetricValue < spe.otherMetricValue,
                                    })}>
                                        <div className='h-8 content-center'> {spe.value.metricValue || spe.value} </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            {showRawDataModal && (
                <CustomModal
                    open={showRawDataModal}
                    modalHandler={() => setShowRawDataModal(false)}
                    className="max-w-[50vw]">
                    <ModalHeader>
                        {rawData[0] ? 
                            `${selectedMetricName} - ${rawData[0]?.Category}` 
                            : `${selectedMetricName} - ${rawData[1]?.Category}`}
                    </ModalHeader>
                    <ModalBody>
                        <div className='flex justify-around p-4 overflow-auto'>
                            <div className='flex flex-col gap-2 max-h-[300px]'>
                                <h4>Baseline: </h4>
                                <div className='whitespace-pre p-4 bg-base-100 rounded '>
                                    {JSON.stringify(rawData[0], null, 4)}
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 max-h-[300px]'>
                                <h4>Result: </h4>
                                <div className='whitespace-pre p-4 bg-base-100 rounded'>
                                    {JSON.stringify(rawData[1], null, 4)}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button 
                            className='btn btn-primary btn-outline'
                            onClick={() => setShowRawDataModal(false)}>
                                Close
                        </button>
                    </ModalFooter>
                </CustomModal>
            )}
        
        
        </div>
        
    )
}

export default PresetEvaluationResult