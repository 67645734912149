import { useCallback } from 'react'
import { useWire } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import { snippet } from '@utils'
import { formatDate } from '@utils/date'
import { toast } from 'react-toastify'
import cn from 'classnames'

import Toggle from '$components/Forms/Toggle'
import Checkbox from '$components/Forms/Checkbox'
import CopyToClipboardButton from '$components/CopyToClipboardButton'
import { MdBolt, MdEmail } from 'react-icons/md'
import { FaUserNinja } from 'react-icons/fa'
import { BsSortUp, BsSortDown } from 'react-icons/bs'

import './UsersTable.css'

const UsersTable = ({
    className,
    users,
    onUserUpdated,
    headers,
    orderField,
    orderDirection,
    onHeaderClick,
    checkedItemsState,
    
    // @todo can remove once payments is live
    paymentsEnabled,
}) => {
    
    const modalEditUserRolesUser = useWire(store.modalEditUserRolesUser)
    const modalEditUserRolesOpen = useWire(store.modalEditUserRolesOpen)
    
    const modalManageUserCreditsUser = useWire(store.modalManageUserCreditsUser)
    const modalManageUserCreditsOpen = useWire(store.modalManageUserCreditsOpen)
    
    const modalMasqueradeConfirmUser = useWire(store.modalMasqueradeConfirmUser)
    const modalMasqueradeConfirmOpen = useWire(store.modalMasqueradeConfirmOpen)
    
    const {
        allChecked,
        someChecked,
        hasChecked,
        toggleChecked,
        toggleAllChecked,
    } = checkedItemsState
    
    const onToggleUserProp = useCallback(async (user, key, value) => {
        
        try {
            
            const res = await actions.updateUser(user.id, {
                [key]: value,
            })
            
            if (!res.data)
                throw new Error('onToggleUserProp: Update failed - no data')
            
            console.log('onToggleUserProp', res)
            
            onUserUpdated(res.data)
            
        } catch (e) {
            
            // @todo show error
            console.error('onToggleUserProp', e)
            
        }
        
    }, [users])
    
    const onResendSignupEmailClick = useCallback(async id => {
        
        try {
            await actions.resendSignupEmail([id])
            toast.success('Signup email re-sent')
        } catch (e) {
            console.error('onResendSignupEmailClick', e)
            toast.error('Failed to re-send signup email')
        }
        
    }, [])
    
    return (
        
        <table className={cn('UsersTable table table-auto table-pin-rows w-full', className)}>
            
            <thead>
                <tr>
                    <th>
                        <Checkbox
                            checked={allChecked}
                            indeterminate={someChecked}
                            onChange={toggleAllChecked} />
                    </th>
                    {headers.map(([slug, label, extraClassName]) => (
                        <th
                            key={slug}
                            className={extraClassName ?? ''}
                            onClick={() => onHeaderClick(slug)}>
                            <div>
                                <span>{label}</span>
                                {orderField === slug && (
                                    orderDirection === 'DESC' ? <BsSortDown /> : <BsSortUp />
                                )}
                            </div>
                        </th>
                    ))}
                    <th>
                        {/* Actions */}
                        <div className="flex justify-center">
                            <MdBolt className="!ml-auto block mx-auto text-lg" />
                        </div>
                    </th>
                </tr>
            </thead>
            
            <tbody>
                {users?.map(it => (
                    <tr key={`UsersTable-user-${it.id}`}>
                        <td>
                            <Checkbox
                                checked={hasChecked(it.id)}
                                onChange={() => toggleChecked(it.id)}/>
                        </td>
                        <td className={headers[0][2] ?? ''} title={it.id}>
                            <div className="flex items-center content-center gap-3">
                                <span>{snippet(it.id)}</span>
                                <CopyToClipboardButton value={it.id}/>
                            </div>
                        </td>
                        <td className={headers[1][2] ?? ''}>{formatDate(it.createdAt, 'MMM DD, YYYY')}</td>
                        <td className={headers[2][2] ?? ''}>{it.firstName}</td>
                        <td className={headers[3][2] ?? ''}>{it.lastName}</td>
                        <td className={headers[4][2] ?? ''}>
                            <div className="flex items-center content-center gap-3">
                                <span>{it.email}</span>
                                <CopyToClipboardButton value={it.email}/>
                            </div>
                        </td>
                        <td className={headers[5][2] ?? ''}>
                            <div className="flex items-center content-center gap-3">
                                <Toggle
                                    checked={it.enabled}
                                    onChange={value => onToggleUserProp(it, 'enabled', value)}/>
                            </div>
                        </td>
                        <td className={headers[6][2] ?? ''}>
                            <div className="flex items-center content-center gap-3">
                                <Toggle
                                    checked={it.isAdmin}
                                    onChange={value => onToggleUserProp(it, 'isAdmin', value)}/>
                            </div>
                        </td>
                        {paymentsEnabled && (
                            <td>
                                <button className="btn break-keep" onClick={() => {
                                    modalManageUserCreditsUser.setValue(it)
                                    modalManageUserCreditsOpen.setValue(true)
                                }}>
                                    ${it.credits?.toFixed(2) ?? '0.00'}
                                </button>
                            </td>
                        )}
                        <td>
                            <div className="flex items-center content-center gap-3">
                                <button
                                    className="btn btn-square"
                                    title={it.roles?.map(it => it.name).join(', ')}
                                    onClick={() => {
                                        modalEditUserRolesUser.setValue(it)
                                        modalEditUserRolesOpen.setValue(true)
                                    }}>
                                    {it.roles.length}
                                </button>
                            </div>
                        </td>
                        <td>
                            <div className="flex items-center content-center justify-center gap-3">
                                <button
                                    className="btn btn-square"
                                    title="Re-send signup email"
                                    onClick={() => onResendSignupEmailClick(it.id)}>
                                    <MdEmail/>
                                </button>
                                <button
                                    className="btn btn-square"
                                    title={`Masquerade as ${it.firstName}`}
                                    onClick={() => {
                                        modalMasqueradeConfirmUser.setValue(it)
                                        modalMasqueradeConfirmOpen.setValue(true)
                                    }}>
                                    <FaUserNinja/>
                                </button>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        
        </table>
        
    )
    
}

export default UsersTable
