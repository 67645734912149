import { useEffect, useMemo, useState, useCallback } from 'react'
import * as jose from 'jose'
import { toast } from 'react-toastify'
import { FaPlus, FaMinus } from 'react-icons/fa'
import config from '@/config'
import useRepeatableViewModel from '@components/shared/Repeatable/RepeatableViewModel'
import Repeatable from '@components/shared/Repeatable/Repeatable'

/**
 * Creates a JWT for service authentication
 *
 * @param {TODOservice} service - Service with ID and key
 * @returns {Promise<string>}
 */
const createServiceAuthJwt = async service => {
    
    const alg = 'HS256'
    const secret = new TextEncoder().encode(service.key)
    
    const payload = {
        sub: `parse-service:${service.id}`,  // Identifier for the third party
        aud: 'parse-api', // Intended audience for the token
    }
    
    return await new jose.SignJWT(payload)
        .setProtectedHeader({ alg })
        .setIssuedAt()
        .setSubject(payload.sub)
        .setAudience(payload.aud)
        .setExpirationTime('1h')
        .sign(secret)
    
}

const TestServiceApi = ({
    serviceApiKeys,
}) => {
    
    const [service, setService] = useState('')
    const [endpoint, setEndpoint] = useState('')
    const [method, setMethod] = useState('POST')
    const [token, setToken] = useState('')
    const [file, setFile] = useState('')
    const [fileFieldName, setFileFieldName] = useState('file')
    
    const url = useMemo(() => {
        
        if (!service) return null
        
        let path = endpoint ? `${endpoint}` : ''
        
        if (path && !path.startsWith('/')) path = '/' + path
        
        return `${config.parseApiBaseUrl}/api/v1/services${path}`
        
    }, [service, endpoint])
    
    const isReady = useMemo(() => (
        service?.key?.length > 0 && endpoint?.length > 0 && url?.length > 0
    ), [service, endpoint, url])
    
    const repeatableViewModel = useRepeatableViewModel(1)
    
    const validExtraFields = useMemo(() => (
        repeatableViewModel.items
            .filter(it => Object.values(it)[0]?.length > 0)
    ), [repeatableViewModel.items])
    
    const onServiceChange = e =>
        setService(serviceApiKeys.find(it => it.id === e.target.value))
    
    const onTestServiceClick = useCallback(async () => {
        
        const nextToken = await createServiceAuthJwt(service)
        
        setToken(nextToken)
        
        console.log('Testing service', {
            service,
            endpoint,
            token,
            url,
        })
        
        let res
        
        try {
            
            const body = new FormData()
            
            // Append extra fields
            validExtraFields.forEach(field => {
                
                const key = Object.keys(field)[0]
                const value = field[key]
                
                body.append(key, value)
                
            })
            
            if (file)
                body.append(fileFieldName, file)
            
            const options = {
                method,
                headers: {
                    Authorization: `Bearer ${nextToken}`,
                    'X-Service-Key-Id': service.id,
                },
            }
            
            if (method !== 'GET')
                options.body = body
            
            res = await fetch(`${url}`, options)
            
            if (!res.ok)
                throw new Error('Failed to fetch data from service')
            
            console.log('Successfully fetched data from service')
            toast.success('Service request successful, see console')
            
            try { console.log(await res.json()) }
            catch (e) { console.log('(no data)') }
            
        } catch (e) {
            
            console.error(`Service authentication failed, status=${res.status}`, e)
            toast.error('Service request failed')
            
            try { console.log(await res.json()) }
            catch (e) { console.log('(no data)') }
            
        }
        
    }, [service, endpoint, token, file, fileFieldName, validExtraFields])
    
    useEffect(() => {
        
        // Set initial service, if there is none yet
        if (service || !serviceApiKeys?.length) return
        
        setService(serviceApiKeys[0])
        
    }, [service, serviceApiKeys])
    
    return (<>
        
        <div className="TestServiceApi">
            
            <header className="flex items-baseline justify-between content-baseline mb-1">
                <h5>Test Service API</h5>
                <p>todo filter</p>
            </header>
            
            <table className="table table-auto w-full">
                <tbody>
                    <tr>
                        <th>Service:</th>
                        <td>
                            <select
                                className="select select-bordered select-sm"
                                value={service.id}
                                onChange={onServiceChange}>
                                {serviceApiKeys?.map(it => (
                                    <option key={it.id} value={it.id}>
                                        {it.name}{it.organization?.name ? ` / ${it.organization?.name}` : ''}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Endpoint:</th>
                        <td>
                            <input
                                className="input input-bordered input-sm"
                                type="text"
                                placeholder="demo"
                                value={endpoint}
                                onChange={e => setEndpoint(e.target.value)}/>
                        </td>
                    </tr>
                    <tr>
                        <th>Method:</th>
                        <td>
                            <select
                                className="select select-bordered select-sm"
                                value={method}
                                onChange={e => setMethod(e.target.value)}>
                                <option value="POST">POST</option>
                                <option value="GET">GET</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>File:</th>
                        <td>
                            <input
                                className="input input-bordered input-sm"
                                type="text"
                                placeholder="file"
                                value={fileFieldName}
                                onChange={e => setFileFieldName(e.target.value)}/>
                            <input
                                className="input input-bordered input-sm"
                                type="file"
                                onChange={e => setFile(e.target.files?.[0])}/>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <div className="w-auto flex justify-between items-center content-center gap-4">
                                <div>Extra Fields</div>
                                <div>
                                    <button
                                        className="btn btn-sm btn-square btn-secondary"
                                        onClick={repeatableViewModel.onAddItemClick}>
                                        <FaPlus className="!ml-0"/>
                                    </button>
                                </div>
                            </div>
                        </th>
                        <td>
                            <Repeatable viewModel={repeatableViewModel}>
                                {(key, value, i, onKeyChange, onValueChange) => (
                                    <div key={`extra-field-${i}`} className="flex items-center content-center gap-2">
                                        <input
                                            className="input input-bordered input-sm"
                                            type="text"
                                            placeholder="key"
                                            value={key}
                                            onChange={e => onKeyChange(i, e)} />
                                        <input
                                            className="input input-bordered input-sm"
                                            type="text"
                                            placeholder="value"
                                            value={value}
                                            onChange={e => onValueChange(i, e)} />
                                        <button
                                            className="btn btn-sm btn-square btn-warning"
                                            onClick={() => repeatableViewModel.onRemoveItemClick(i)}>
                                            <FaMinus className="!ml-0"/>
                                        </button>
                                    </div>
                                )}
                            </Repeatable>
                            
                            {/* {extraFields.map((field, i) => {
                                
                                const key = Object.keys(field)[0]
                                const value = field[key]
                                
                                return (
                                    <div key={`extra-field-${i}`} className="flex items-center content-center gap-2">
                                        <input
                                            className="input input-bordered input-sm"
                                            type="text"
                                            placeholder="key"
                                            value={key}
                                            onChange={e => onExtraFieldChange(i, e)}/>
                                        <input
                                            className="input input-bordered input-sm"
                                            type="text"
                                            placeholder="value"
                                            value={value}
                                            onChange={e => onExtraFieldChange(i, null, e)}/>
                                        <button
                                            className="btn btn-sm btn-square btn-warning"
                                            onClick={() => onRemoveExtraFieldClick(i)}>
                                            <FaMinus className="!ml-0"/>
                                        </button>
                                    </div>
                                )
                                
                            })} */}
                        </td>
                    </tr>
                    <tr>
                        <th>Summary</th>
                        <td>
                            <table className="table table-auto w-full">
                                <tbody>
                                    <tr>
                                        <td><b>Key:</b></td>
                                        <td>{service?.key}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Endpoint:</b></td>
                                        <td>{endpoint}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Target:</b></td>
                                        <td>{url}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Token:</b></td>
                                        <td><span className="break-all">{token}</span></td>
                                    </tr>
                                    <tr>
                                        <td><b>File:</b></td>
                                        <td>&quot;{fileFieldName ?? 'N/A'}&quot; = {file?.name ?? 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Extra Fields:</b></td>
                                        <td>
                                            {/* <div>
                                                    <pre><code>{JSON.stringify(extraFields, null, 4)}</code></pre>
                                                </div> */}
                                            <ul className="list-disc pl-5">
                                                {validExtraFields.map(it => {
                                                    
                                                    const key = Object.keys(it)[0]
                                                    const value = it[key]
                                                    
                                                    return (
                                                        <li key={key}><b>{key}:</b> {value}</li>
                                                    )
                                                    
                                                })}
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div className="flex items-center content-center gap-2">
                <button
                    className="btn btn-success text-3xl"
                    disabled={!isReady}
                    onClick={onTestServiceClick}>
                    TEST SERVICE
                </button>
            </div>
        
        </div>
    
    </>)
    
}

export default TestServiceApi
