import api from '$lib/api'
import * as store from '$store'
import { DOC_NOT_READY_STATUSES, DOC_READY_STATUSES } from '@constants'
import { createQueryString } from '@utils'

export const getDocumentsCount = async (collectionId = null) => {
    
    const query = createQueryString({
        collectionId,
    })
    
    const res = await api.get(`/document/count${query}`)
    
    return res.data.count
    
}

export const _getDocuments = async (collectionId, { offset, limit, reviewStatus } = {}) => {
    
    const query = createQueryString({
        collectionId,
        offset,
        limit,
        reviewStatus,
    })
    
    const res = await api.get(`/document${query}`)
    
    return res
    
}

export const getDocuments = async (collectionId, { offset, limit, reviewStatus } = {}) => {
    
    const res = await _getDocuments(collectionId, { offset, limit, reviewStatus })
    
    if (res.data?.rows) {
        console.info('Fetched', res.data.rows.length, 'documents')
        store.documents.setValue(res.data.rows)
    } else {
        console.warn('getDocuments', res)
    }
    
    return res
    
}

export const getAllDocuments = async (pageIndex = 0, onProgress = () => { }) => {
    
    const limit = 100
    const offset = pageIndex * 100
    
    const pendingQuery = createQueryString(
        DOC_NOT_READY_STATUSES.map(it => ['reviewStatuses[]', it]),
        {
            offset,
            limit,
        },
    )
    
    const pending = await api.get(`/document${pendingQuery}`)
    
    onProgress(50, 100, '')
    
    const notPendingQuery = createQueryString(
        DOC_READY_STATUSES.map(it => ['reviewStatuses[]', it]),
        {
            offset,
            limit,
        },
    )
    
    const notPending = await api.get(`/document${notPendingQuery}`)
    
    onProgress(100, 100, '')
    
    store.documents.setValue([
        ...pending.data.rows,
        ...notPending.data.rows,
    ])
    
}

export const getDocumentsWithoutPages = async () => {
    
    const res = await api.get('/admin/misc/documents/withoutpages')
    
    return res.data?.count ?? 0
    
}

export const requeueDocumentsMissingPages = async () => {
    
    const res = await api.post('/admin/misc/documents/requeue')
    
    return res
    
}

export const assignDocumentsToAnnotators = async (annotatorIds, documentIds) => {
    
    const res = await api.post('/document/assign', {
        annotatorIds,
        documentIds,
    })
    
    return res
    
}

export const getDocumentReviewsByCollectionId = async collectionId => {
    
    const res = await api.get(`/documentReviews/collection/${collectionId}`)
    
    return res
    
}
