import { useEffect, useState } from 'react'
import { useWire } from '@forminator/react-wire'
import * as store from '$store'
import { snippet } from '@utils'
import { formatDate } from '@utils/date'
import cn from 'classnames'

import ManageOrgMembersModal from '$components/Modals/ManageOrgMembersModal'
import ManageOrgLabelsModal from '$components/Modals/ManageOrgLabelsModal'
import CopyToClipboardButton from '$components/CopyToClipboardButton'
import { MdBolt, MdGroupAdd, MdPeople, MdLabel, MdSettings } from 'react-icons/md'
import { FaMoneyCheckAlt } from 'react-icons/fa'
import { BsSortUp, BsSortDown } from 'react-icons/bs'

import './OrgsTable.css'
import ManageLLMPromptModal from '$components/Modals/ManageLLMPromptModal'

const OrgsTable = ({
    className,
    orgs,
    headers,
    orderField,
    orderDirection,
    paymentsEnabled,
    onHeaderClick,
    onAssignAnnotatorClick,
}) => {
    
    const [expandedOrgMembers, setExpandedOrgMembers] = useState([])
    const [selectedOrg, setSelectedOrg] = useState(null)
    
    const modalManageOrgMembersOpen = useWire(store.modalManageOrgMembersOpen)
    const modalManageOrgCreditsOrg = useWire(store.modalManageOrgCreditsOrg)
    const modalManageOrgCreditsOpen = useWire(store.modalManageOrgCreditsOpen)
    const modalManageOrgLabelsOpen = useWire(store.modalManageOrgLabelsOpen)
    const modalManageLLMPromptOpen = useWire(store.modalManageLLMPromptOpen)
    
    
    const toggleExpandedOrg = orgId => setExpandedOrgMembers(prev => {
        if (prev.includes(orgId))
            return prev.filter(it => it !== orgId)
        return [...prev, orgId]
    })
    
    const onManageLabelsClick = org => {
        setSelectedOrg(org)
        modalManageOrgLabelsOpen.setValue(true)
    }
    
    const onSelfJoinClick = org => {
        setSelectedOrg(org)
        console.warn('@todo not implemented yet')
        
        // modalSelfJoinOrgOpen.setValue(true)
    }
    
    const onManageMembersClick = org => {
        setSelectedOrg(org)
        modalManageOrgMembersOpen.setValue(true)
    }
    
    const onManageLLMConfigClick = org => {
        
        setSelectedOrg(org)
        modalManageLLMPromptOpen.setValue(true)
        
    }
    
    useEffect(() => setSelectedOrg(prev => (
        orgs.find(it => it.id === prev?.id)
    )), [orgs])
    
    return (<>
        
        <table className={cn('OrgsTable table table-auto table-pin-rows', className)}>
            
            <thead>
                <tr>
                    {headers.map(([slug, label, unsortable]) => (
                        <th
                            key={slug}
                            onClick={unsortable ? () => {} : () => onHeaderClick(slug)}>
                            {!unsortable && (
                                <div>
                                    <span>{label}</span>
                                    {orderField === slug && (
                                        orderDirection === 'DESC' ? <BsSortDown /> : <BsSortUp />
                                    )}
                                </div>
                            )}
                        </th>
                    ))}
                    <th>
                        {/* Actions */}
                        <div className="flex justify-center">
                            <MdBolt className="text-lg" />
                        </div>
                    </th>
                </tr>
            </thead>
            
            <tbody>
                {orgs?.map(it => (
                    <tr key={`OrgsTable-org-${it.id}`}>
                        <td title={it.id}>
                            <div className="flex items-center content-center gap-3">
                                <span>{snippet(it?.id ?? '')}</span>
                                <CopyToClipboardButton value={it.id} />
                            </div>
                        </td>
                        <td>
                            <div className="flex items-center content-center gap-3">
                                <span>{it.name}</span>
                                <CopyToClipboardButton value={it.name} />
                            </div>
                        </td>
                        <td>{formatDate(it.createdAt, 'MMM DD, YYYY')}</td>
                        <td>
                            <div className="">
                                <div className="flex items-center content-center gap-4">
                                    <div>{it.members?.length ?? 0}</div>
                                    <button
                                        className="w-auto btn btn-sm"
                                        onClick={() => toggleExpandedOrg(it.id)}>
                                        {expandedOrgMembers.includes(it.id) ? 'HIDE' : 'SHOW'} MEMBERS
                                    </button>
                                </div>
                                {expandedOrgMembers.includes(it.id) && (
                                    <div className="pt-2 mt-2 border-t border-sky-700">
                                        <ul>
                                            {it.members.map(user => (
                                                <li key={`${it.id}-${user.id}`}>
                                                    {user.email}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </td>
                        {paymentsEnabled && (
                            <td>
                                <button className="btn break-keep" onClick={() => {
                                    modalManageOrgCreditsOrg.setValue(it)
                                    modalManageOrgCreditsOpen.setValue(true)
                                }}>
                                    ${it.credits?.toFixed(2) ?? '0.00'}
                                </button>
                            </td>
                        )}
                        <td>
                            <div className="flex items-center content-center gap-3">
                                {/* <button
                                    className="w-auto btn"
                                    title="Todo"
                                    onClick={() => console.log('@todo')}>
                                    <MdEmail />
                                </button> */}
                                {/* @todo quick self-join org needs implement */}
                                <button
                                    className="hidden w-auto text-lg btn btn-link btn-square !text-orange-400"
                                    title="Join Organization"
                                    onClick={() => onSelfJoinClick(it)}>
                                    <MdGroupAdd />
                                </button>
                                <button
                                    className="w-auto text-lg btn btn-link btn-square"
                                    title="Manage Members"
                                    onClick={() => onManageMembersClick(it)}>
                                    <MdPeople />
                                </button>
                                <button
                                    className="w-auto text-lg btn btn-link btn-square"
                                    title="Manage Labels"
                                    onClick={() => onManageLabelsClick(it)}>
                                    <MdLabel />
                                </button>
                                <button
                                    className="w-auto text-xs btn btn-link btn-square"
                                    title="Assign Checks"
                                    onClick={() => onAssignAnnotatorClick(it)}>
                                    <FaMoneyCheckAlt />
                                </button>
                                <button
                                    className="w-auto text-xs btn btn-link tooltip tooltip-left"
                                    data-tip="LLM Prompt Configuration"
                                    onClick={() => onManageLLMConfigClick(it)}>
                                    <MdSettings className="text-xl" />
                                </button>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        
        </table>
        
        <ManageOrgMembersModal organization={selectedOrg} />
        <ManageOrgLabelsModal organization={selectedOrg} />
        <ManageLLMPromptModal
            id={selectedOrg?.id}
            onClose={() => setSelectedOrg(null)}
            routeBase="orgPromptConfig" />
    
    </>)
    
}

export default OrgsTable
