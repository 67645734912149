import { useCallback, useEffect, useState } from 'react'
import { useWireState } from '@forminator/react-wire'
import { modalManageOrgMembersOpen } from '$store'
import * as actions from '$actions'
import { toast } from 'react-toastify'
import useCheckableItems from '@hook/useCheckableItems'
import { getUserFullName } from '@utils'
import cn from 'classnames'

import Checkbox from '$components/Forms/Checkbox'
import Modal from '$components/Modal'
import { MdDelete } from 'react-icons/md'
import CircleSpinner from '@components/shared/CircleSpinner'

const ManageOrgMembersModal = ({
    organization,
    ...props
}) => {
    
    const [roles, setRoles] = useState(null)
    const [members, setMembers] = useState([])
    const [newMemberEmail, setNewMemberEmail] = useState('')
    const [newMemberRole, setNewMemberRole] = useState(null)
    const [addMemberLoading, setAddMemberLoading] = useState(false)
    const [removeMemberLoading, setRemoveMemberLoading] = useState(null)
    const [removeMembersLoading, setRemoveMembersLoading] = useState(false)
    
    const [open, setOpen] = useWireState(modalManageOrgMembersOpen)
    
    const {
        checked,
        allChecked,
        someChecked,
        hasChecked,
        toggleChecked,
        toggleAllChecked,
    } = useCheckableItems(members)
    
    const onAddNewMemberClick = useCallback(async () => {
        
        if (!newMemberEmail?.length) return
        
        setAddMemberLoading(true)
        
        try {
            await actions.addMemberToOrganization(organization.id, newMemberEmail, newMemberRole)
            await actions.getOrganizationById(organization.id)
            setNewMemberEmail('')
        } catch (e) {
            console.error('onAddNewMemberClick', e)
            
            if (e.response && e.response.status === 409) {
                toast.error('Already a member of this organization')
            } else {
                toast.error(<>
                    <div>Failed to add user to org</div>
                    <div className="text-sm">
                        {e.response.data.error ?? e.message}
                    </div>
                </>)
            }
        }
        
        setAddMemberLoading(false)
        
    }, [organization, newMemberEmail, newMemberRole])
    
    const onRemoveMemberClick = useCallback(async member => {
        
        setRemoveMemberLoading(member.id)
        
        try {
            await actions.removeMemberFromOrganization(organization.id, member.id)
            await actions.getOrganizationById(organization.id)
        } catch (e) {
            console.error('onRemoveMemberClick', e)
            toast.error(<>
                <div>Failed to remove {member.email} from org</div>
                <div className="text-sm">
                    {e.response.data.error ?? e.message}
                </div>
            </>)
        }
        
        setRemoveMemberLoading(false)
        
    }, [organization])
    
    const onRemoveSelectedMembersClick = useCallback(async () => {
        
        if (!checked?.length) return
        
        setRemoveMembersLoading(true)
        
        const items = checked.map(id => organization?.members?.find(it => it.id === id))
        
        await Promise.all(items.map(it => onRemoveMemberClick(it)))
        
        setRemoveMembersLoading(false)
        
    }, [checked])
    
    useEffect(() => {
        
        if (!open) return
        
        setMembers(organization?.members ?? [])
        
    }, [open, organization])
    
    useEffect(() => {
        
        if (roles) return
        
        actions.getOrganizationRoles()
            .then(it => {
                setRoles(it)
                setNewMemberRole(it[0])
            })
            .catch(e => {
                console.error('Failed to fetch list of org roles', e)
                toast.error('Failed to fetch list of org roles')
            })
        
    }, [roles])
    
    return (
        
        <Modal
            className="ManageOrgMembersModal overflow-x-auto"
            contentClassName="flex flex-col gap-4"
            style={{ maxWidth: '80%', maxHeight: '80%' }}
            open={open}
            setOpen={setOpen}
            title="Manage Organization Members"
            subtitle={organization?.name ?? ''}
            actionLabel="CLOSE"
            actionOnClick={() => setOpen(false)}
            showCancel={false}
            {...props}>
            
            <div className="p-3 rounded bg-primary">
                <h5>Add New Member</h5>
                <div className="flex items-center gap-4">
                    <input
                        className="w-full"
                        type="email"
                        value={newMemberEmail}
                        placeholder="someone@parseai.co"
                        onKeyUp={e => e.key === 'Escape' && setNewMemberEmail('')}
                        onChange={e => setNewMemberEmail(e.target.value?.toLowerCase()?.trim() ?? '')} />
                    <select
                        className="bg-transparent rounded border-base-200/70"
                        value={newMemberRole ?? ''}
                        onChange={e => setNewMemberRole(e.target.value)}>
                        <option className='bg-base-200' value={null} disabled>
                            Role
                        </option>
                        {roles?.map(role => (
                            <option className='bg-base-200' key={role} value={role}>
                                {role}
                            </option>
                        ))}
                    </select>
                    <button
                        className="btn btn-info"
                        onClick={onAddNewMemberClick}>
                        {!addMemberLoading && roles?.length > 0 && 'ADD MEMBER'}
                        {(addMemberLoading || !roles?.length) && (
                            <span className="loading loading-spinner"></span>
                        )}
                    </button>
                </div>
            </div>
            
            <div className="mt-6">
                
                <h5>Existing Members</h5>
                
                <table className="table table-auto w-full">
                    <thead>
                        <tr>
                            <th>
                                <Checkbox
                                    className="ml-3"
                                    checked={allChecked}
                                    indeterminate={someChecked}
                                    onChange={toggleAllChecked} />
                            </th>
                            <th>Email</th>
                            <th>Full Name</th>
                            <th>
                                <div className="flex justify-center">
                                    <button
                                        className="
                                            btn !bg-transparent btn-ghost btn-sm btn-square
                                            btn-error hover:!text-red-400"
                                        disabled={removeMembersLoading}
                                        onClick={onRemoveSelectedMembersClick}>
                                        {removeMembersLoading ? (
                                            <span className="loading loading-spinner"></span>
                                        ) : (
                                            <MdDelete />
                                        )}
                                    </button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {members?.map(it => {
                            
                            const fullName = getUserFullName(it, false)
                            
                            return (
                                <tr key={`ManageOrgMembersModal-member-${it.id}`}>
                                    <td>
                                        <Checkbox
                                            className=""
                                            disabled={allChecked}
                                            checked={hasChecked(it.id)}
                                            onChange={() => toggleChecked(it.id)} />
                                    </td>
                                    <td>{it.email}</td>
                                    <td>
                                        <span className={cn({
                                            'opacity-30': !fullName?.length,
                                        })}>
                                            {fullName?.length > 0 ? fullName : 'N/A'}
                                        </span>
                                    </td>
                                    <td>
                                        <div className="flex justify-center items-center gap-3">
                                            <button
                                                className="
                                                    btn !bg-transparent btn-ghost btn-sm btn-square
                                                    btn-error hover:!text-red-400"
                                                disabled={removeMemberLoading}
                                                onClick={() => onRemoveMemberClick(it)}>
                                                {removeMemberLoading === it.id ? (
                                                    <span className="loading loading-spinner"></span>
                                                ) : (
                                                    <MdDelete />
                                                )}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                            
                        })}
                    </tbody>
                </table>
            
            </div>
            
            {!roles && (
                <div className="absolute inset-0 backdrop-blur">
                    <CircleSpinner className="absolute absolute-centered" />
                </div>
            )}
        
        </Modal>
        
    )
    
}

export default ManageOrgMembersModal
