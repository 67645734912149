import { useState, useMemo, useEffect, useCallback } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import { debounceAsync } from '@utils'
import { toast } from 'react-toastify'
import Modal from '$components/Modal'
import { FaTimes } from 'react-icons/fa'
import CircleSpinner from '@components/shared/CircleSpinner'

const refreshOrganizations = debounceAsync((query, offset, limit) => {
    
    try {
        
        // @todo this is mess
        // Don't offset results if searching
        // offset = !query?.length ? Math.round(pageIndex * collectionsPerPage) : offset
        offset = query?.length ? 0 : offset
        
        return actions.getOrganizations(query, offset, limit)
        
    } catch (e) {
        
        console.error('const refreshOrganizations', e)
        toast.error('Failed to fetch organizations')
        
    }
    
}, 1000)

const CreateServiceApiKeyModal = ({
    open,
    setOpen,
    
    ...props
}) => {
    
    const [error, setError] = useState(null)
    const [isCreatingKey, setIsCreatingKey] = useState(false)
    const [name, setName] = useState('')
    const [allowedDomains, setAllowedDomains] = useState('')
    const [allowedEndpoints, setAllowedEndpoints] = useState('')
    const [orgs, setOrgs] = useState([])
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [orgsQuery, setOrgsQuery] = useState('')
    
    const isFetchingOrgs = useWireValue(store.isFetchingOrgs)
    
    const loading = useMemo(() => isFetchingOrgs || isCreatingKey, [isFetchingOrgs, isCreatingKey])
    
    const onCreateServiceApiKeyClick = useCallback(async () => {
        
        setIsCreatingKey(true)
        
        try {
            
            await actions.createServiceApiKey({
                name,
                organizationId: selectedOrg?.id,
                allowedDomains,
                allowedEndpoints,
            })
            
            await actions.fetchServiceApiKeys()
            
            toast.success('Created service API key')
            
            setOpen(false)
            
        } catch (e) {
            
            // @todo show error
            console.error('onCreateServiceApiKeyClick', e)
            
            toast.error('Failed to create service API key')
            
        }
        
        setIsCreatingKey(false)
        
    }, [name, selectedOrg, allowedDomains, allowedEndpoints])
    
    useEffect(() => {
        
        if (!open) return
        
        setError(null)
        
        refreshOrganizations(orgsQuery)
            .then(res => setOrgs(res.data || []))
            .catch(e => {
                if (e === 'canceled') return
                console.error('CreateServiceApiKeyModal#effect.fetchOrgs', e)
                setError(e?.message ?? 'Unknown error')
            })
        
    }, [open, orgsQuery])
    
    useEffect(() => {
        
        if (open) return
        
        // Cleanup
        setName('')
        setSelectedOrg(null)
        setAllowedDomains('')
        setAllowedEndpoints('')
        setOrgsQuery('')
        
    }, [open])
    
    return (
        
        <Modal
            className="CreateServiceApiKeyModal overflow-x-auto"
            contentClassName="flex flex-col gap-4"
            style={{ maxWidth: '80%', maxHeight: '80%' }}
            open={open}
            setOpen={setOpen}
            title="Create Service API Key"
            actionLabel="CREATE KEY"
            actionDisabled={!name?.length || isCreatingKey}
            actionOnClick={onCreateServiceApiKeyClick}
            showCancel={false}
            {...props}>
            
            {error && (
                <div className="p-3 rounded bg-error text-error-content">
                    <p>Failed to fetch organizations.</p>
                    <p>Error: {error}</p>
                </div>
            )}
            
            {loading && (
                <div className="w-full">
                    <CircleSpinner className="absolute-centered" />
                </div>
            )}
            {isFetchingOrgs && <p>Fetching organizations...</p>}
            {isCreatingKey && <p>Creating key...</p>}
            
            {!loading && orgs?.length > 0 && (
                <div className="p-3 rounded">
                    <p className="font-bold">
                        Name Your Key
                    </p>
                    <input
                        className="w-full"
                        type="text"
                        value={name}
                        placeholder="My Service API Key"
                        onChange={e => setName(e.target.value ?? '')}/>
                </div>
            )}
            
            {!loading && orgs?.length > 0 && (
                <div className="p-3 rounded">
                    <p className="font-bold">
                        Allowed Domains
                    </p>
                    <input
                        className="w-full"
                        type="text"
                        value={allowedDomains}
                        placeholder="https://api.acme.org"
                        onChange={e => setAllowedDomains(e.target.value ?? '')}/>
                </div>
            )}
            
            {!loading && orgs?.length > 0 && (
                <div className="p-3 rounded">
                    <p className="font-bold">
                        Allowed Endpoints
                    </p>
                    <input
                        className="w-full"
                        type="text"
                        value={allowedEndpoints}
                        placeholder="/acme"
                        onChange={e => setAllowedEndpoints(e.target.value ?? '')}/>
                </div>
            )}
            
            {!loading && orgs?.length > 0 && (
                <div className="p-3 rounded">
                    <p className="font-bold">
                        Select an organization <i>(optional)</i>
                    </p>
                    {selectedOrg && (
                        <div className="flex items-center gap-4">
                            <p>Selected: {selectedOrg?.name}</p>
                            <button
                                className="btn btn-ghost btn-sm"
                                title="Clear selection"
                                onClick={() => setSelectedOrg(null)}>
                                <FaTimes className="text-sm" />
                            </button>
                        </div>
                    )}
                    <div className="flex items-center gap-4">
                        <input
                            className="w-full"
                            type="text"
                            value={orgsQuery}
                            placeholder="Search organizations..."
                            onKeyUp={e => e.key === 'Escape' && setOrgsQuery('')}
                            onChange={e => setOrgsQuery(e.target.value?.toLowerCase() ?? '')}/>
                    </div>
                </div>
            )}
            
            {!loading && orgs?.length > 0 && (
                <div className="p-3 flex flex-col gap-4 mt-1">
                    <ul>
                        {orgs.slice(0, 5).map(it => (
                            <li
                                key={it.id}
                                className="cursor-pointer"
                                onClick={() => setSelectedOrg(it)}>
                                {it.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        
        </Modal>
        
    )
    
}

export default CreateServiceApiKeyModal
