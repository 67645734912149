import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'
import { createQueryString } from '@/utils'

const log = new Logger('actions/tracts')

const storeKey = 'tracts'

export const fetchTracts = async () => {
    
    try {
        
        const res = await ParseAPI.get('tracts')
        
        store.tracts.setValue(res.data)
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const emptyTract = async () => {
    
    store.currentTract.setValue(null)
    
}

export const emptyTracts = async () => {
    
    store.tracts.setValue([])
    
}

export const fetchTract = async id => {
    
    try {
        
        const res = await ParseAPI.get(`tracts/${id}`)
        
        log.d('fetchTract', res)
        
        store.currentTract.setValue(res.data)
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const createTract = async (tractDefault, ...rest) => {
    
    try {
        
        const tract = { ...tractDefault, ...rest[0] }
        const res = await ParseAPI.post('tracts', {
            tract,
        })
        
        store.arrayUpsertById(storeKey, res.data, (prev, next) => ({
            ...prev,
            ...next,
        }))
        
        const currRunsheet = store.currentRunsheet.getValue()
        
        if (currRunsheet) {
            if (res.data.runsheets.some(rs => rs.id === currRunsheet.id))
                currRunsheet.tract = res.data
            
            if (currRunsheet.tabs?.length) {
                
                currRunsheet.tabs.forEach(tab => {
                    if (res.data.runsheetTabs.some(rs => rs.id === tab.id)) {
                        tab.tractId = res.data.id
                        tab.tract = res.data
                    }
                })
                
            }
            
            store.currentRunsheet.setValue({ ...currRunsheet, tabs: [...currRunsheet.tabs] })
            
        }
        
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const createRecentTract = async recentTract => {
    
    try {
        
        await ParseAPI.post('recentTract', recentTract)
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const fetchRecentTracts = async userId => {
    
    try {
        
        const res = await ParseAPI.get(`recentTract/${userId}`)
        
        store.recentTracts.setValue(res.data)
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const deleteRecentTract = async id => {
    
    try {
        
        const res = await ParseAPI.post(`recentTract/${id}`)
        
        log.w('@todo deleteRecentTract', res.data)
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const createFavoriteTract = async favoriteTract => {
    
    try {
        
        const res = await ParseAPI.post('favoriteTract', favoriteTract)
        
        log.w('@todo createFavoriteTract', res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const fetchFavoriteTracts = async () => {
    
    try {
        
        const res = await ParseAPI.get('favoriteTract')
        
        store.favoriteTracts.setValue(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const deleteFavoriteTract = async id => {
    
    try {
        
        const res = await ParseAPI.post(`favoriteTract/${id}`)
        
        log.w('@todo deleteFavoriteTract', res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const addRunsheet = async (runsheet, callback) => {
    
    const defaultCallback = () => {}
    
    callback = callback || defaultCallback
    
    try {
        
        const { data } = await ParseAPI.post('runsheets', {
            runsheet,
        })
        
        if (!data || !data.id) {
            callback(null)
            return
        }
        
        log.w('@todo addRunsheet', data)
        
        /* await dispatch({
            type: ADD_RUNSHEET,
            payload: data,
        }) */
        
        store.runsheets.setValue([
            data,
            ...store.runsheets.getValue(),
        ])
        
        callback(data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const updateTract = async (id, tractDefault, rest) => {
    
    const tract = { ...tractDefault, ...rest }
    
    try {
        
        const res = await ParseAPI.post(`tracts/${id}`, { tract })
        
        // store.currentTract.setValue(res.data)
        
        store.arrayUpsertById(storeKey, res.data, (prev, next) => ({
            ...prev,
            ...next,
        }))
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const assignTractToUser = async (selectedModelIds = [], selectedUsers = [], which) => {
    
    try {
        
        switch (which) {
            
            case 'Tract': {
                
                const res = await ParseAPI.post('tracts/assign/users', {
                    selectedUser: selectedUsers,
                    selectedModelIds,
                })
                
                // store.currentTract.setValue(res.data)
                res.data.forEach(tract => {
                    
                    store.arrayUpsertById(storeKey, tract, (prev, next) => ({
                        ...prev,
                        ...next,
                    }))
                })
                
                return
                
            }
            
            case 'Runsheet': {
                
                const res = await ParseAPI.post(
                    'runsheets/assign/users',
                    { selectedUsers, selectedModelIds },
                )
                
                store.arrayUpsertById('runsheets', res.data, (prev, next) => ({
                    ...prev,
                    ...next,
                }))
                
                return
                
            }
            
        }
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const findRunsheetsForTract = async () => {
    
    try {
        
        const res = await ParseAPI.get('tracts/runsheets')
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        return []
    }
    
}

export const findLastDeedDetail = async runsheetId => {
    try {
        
        const res = await ParseAPI.get(`tracts/runsheets/${runsheetId}`)
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        return []
    }
}

export const findOwnershipFromTract = async (tractId, runsheetTabId, runsheetId) => {
    try {
        
        const paramsObj = {
            runsheetTabId,
            runsheetId,
        }
        
        const params = createQueryString(paramsObj)
        
        const res = await ParseAPI.get(`tracts/${tractId}/ownership${params}`)
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        return { parties: [], tract: {} }
    }
}

export const deleteTract = async tractId => {
    try {
        
        await ParseAPI.delete(`tracts/${tractId}`)
        
    } catch (err) {
        log.e(err)
    }
}