import { useState, useEffect, useCallback } from 'react'
import { useWireState, useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import cn from 'classnames'


import CustomModal from '@/components/shared/CustomModal/CustomModal'

const CurrentOrgProjectSelectorDialog = ({
    className,
}) => {
    
    const [selectedOrg, setSelectedOrg] = useState(null)
    // const [selectedProject, setSelectedProject] = useState(null)
    
    const [open, setOpen] = useWireState(store.currentOrgProjectSelectorDialogOpen)
    const user = useWireValue(store.user)
    const [currentOrganization, setCurrentOrganization] = useWireState(store.currentOrganization)
    
    
    const closeDialog = () => {
        
        setSelectedOrg(null)
        // setSelectedProject(null)
        
        setOpen(false)
        
    }
    
    const updateCurrentGlobals = useCallback(() => {
        
        setCurrentOrganization(selectedOrg)
        // setCurrentProject(selectedProject)
        
        closeDialog()
        
    }, [selectedOrg])
    
    useEffect(() => {
        
        if (!selectedOrg && currentOrganization)
            setSelectedOrg(currentOrganization)
        
    }, [selectedOrg, currentOrganization])
    
    return (
        
        <CustomModal
            className={cn('CurrentOrgProjectSelectorDialog', className)}
            open={open}
            modalHandler={() => setOpen(false)}
            center>
            
            <div className="modal-body">
                
                <div className="mt-5">
                    <h3 className="text-xl">Current Organization</h3>
                    <p>
                        By setting a &quot;current&quot; organization, all projects, collections,
                        documents, etc. shown will only be from that organization.
                    </p>
                    <ul className="flex flex-wrap gap-2 mt-3">
                        {user?.organizations?.map(it => (
                            <li key={it.id}>
                                <a
                                    className={cn('btn', {
                                        'btn-primary': it.id === selectedOrg?.id,
                                    })}
                                    onClick={() => setSelectedOrg(it)}>
                                    {it.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                
                {/* @todo don't delete, will add projects to selector */}
                {/* <div className="mt-5">
                    <h3 className="text-2xl">Current Project</h3>
                    <ul className="flex flex-wrap gap-2 mt-3">
                        {user?.project?.map(it => (
                            <li key={it.id}>
                                <a
                                    className={cn('btn', {
                                        'btn-primary': it.id === selectedOrg?.id,
                                    })}
                                    onClick={() => setSelectedProject(it)}>
                                    {it.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div> */}
            
            </div>
            
            <div className="flex justify-end modal-footer mt-5">
                <button
                    className="mr-3 btn btn-primary btn-outline"
                    onClick={closeDialog}>
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    onClick={updateCurrentGlobals}>
                    Update
                </button>
            </div>
        
        </CustomModal>
        
    )
    
}

export default CurrentOrgProjectSelectorDialog
