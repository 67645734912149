import { memo } from 'react'

/**
 * Repeatable component for rendering a list of items
 * 
 * @param {RepeatableViewModel} viewModel
 * @param {Function(key, value, index, onChangeKey, onChangeValue)} children
 * @returns {JSX.Element}
 * @constructor
 * @example
 * 
 * import useRepeatableViewModel from './RepeatableViewModel'
 * import Repeatable from './Repeatable'
 *
 * const RepeatableExample = () => {
 *
 *     const vm = useRepeatableViewModel(1)
 *
 *     return (
 *
 *         <div className="flex flex-col gap-8">
 *
 *             <div>
 *                 <button className="btn" onClick={vm.onAddItemClick}>
 *                     ADD FIELD
 *                 </button>
 *             </div>
 *
 *             <div className="flex flex-col gap-2">
 *                 <Repeatable viewModel={vm}>
 *                     {(key, value, i, onKeyChange, onValueChange) => (
 *                         <div key={key} className="flex items-center content-center gap-2">
 *                             <input
 *                                 className="input input-bordered"
 *                                 type="text"
 *                                 value={key}
 *                                 placeholder={`key${i}`}
 *                                 onChange={e => onKeyChange(i, e)} />
 *                             <input
 *                                 className="input input-bordered"
 *                                 type="text"
 *                                 value={value}
 *                                 placeholder={`value${i}`}
 *                                 onChange={e => onValueChange(i, e)} />
 *                         </div>
 *                     )}
 *                 </Repeatable>
 *             </div>
 *
 *             <div className="">
 *                 <pre>
 *                     <code>
 *                         {JSON.stringify(vm.items, null, 4)}
 *                     </code>
 *                 </pre>
 *             </div>
 *
 *         </div>
 *
 *     )
 *
 * }
 *
 * export default RepeatableExample
 */
const Repeatable = ({
    viewModel,
    children,
} = {}) => {
    
    if (typeof children !== 'function')
        throw new Error('Children must be a render function')
    
    return viewModel.items.map((it, i) => {
        
        const key = Object.keys(it)[0]
        const value = it[key]
        
        return children(
            key,
            value,
            i,
            (index, key) => viewModel.onItemChange(index, key),
            (index, val) => viewModel.onItemChange(index, null, val),
        )
        
    })
    
}

export default memo(Repeatable)
