import { useState } from 'react'
import * as actions from '$actions'
import { debounceAsync, formatCurrency, snippet } from '@utils'
import { useQuery } from '@tanstack/react-query'
import { formatDateTime, relativeTime } from '@/utils/date'
import Pagination from '@components/shared/Pagination'
import ServiceUsageTrackingHeader from '$routes/Services/widgets/ServiceUsageTrackingHeader'

const LIMIT = 100

const refreshUsageTrackings = debounceAsync((query, offset, limit) => {
    
    try {
        
        // Don't offset results if searching
        offset = query?.length ? 0 : offset
        
        return actions.getServiceUsageTrackings(query, offset, limit)
        
    } catch (e) {
        
        console.error('const refreshUsageTrackings', e)
        
    }
    
}, 1000)

const ServiceUsageTracking = () => {
    
    const [query, setQuery] = useState('')
    const [pageIndex, setPageIndex] = useState(0)
    const [queryOffset, setQueryOffset] = useState(0)
    const [queryLimit, setQueryLimit] = useState(100)
    
    const { data: usageTrackings, error, isLoading, refetch } = useQuery({
        queryKey: ['usageTrackings'],
        queryFn: () => refreshUsageTrackings(query, LIMIT * pageIndex, LIMIT),
        enabled: true,
        retry: 0,
    })
    
    return (
        
        <div className="ServiceUsageTracking">
            
            <ServiceUsageTrackingHeader
                query={query}
                setQuery={setQuery}
                queryOffset={queryOffset}
                setQueryOffset={setQueryOffset}
                queryLimit={queryLimit}
                setQueryLimit={setQueryLimit}
                refetch={refetch} />
            
            {error && (
                <div className="alert alert-danger">
                    {error}
                </div>
            )}
            
            {isLoading && (
                <div className="flex justify-center p-4 text-center">
                    <div className="loading loading-spinner" />
                </div>
            )}
            
            <table className="table-auto w-full border-collapse mt-4">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Service API Key ID</th>
                        <th>Created</th>
                        <th>Action/Event</th>
                        <th>Organization</th>
                        <th>User ID</th>
                        <th>Object ID</th>
                        <th>Cost</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {usageTrackings?.rows?.map(usage => (
                        <tr key={usage.id}>
                            <td title={usage.id}>{snippet(usage.id)}</td>
                            <td title={usage.serviceApiKeyId}>{snippet(usage.serviceApiKeyId)}</td>
                            <td title={formatDateTime(usage.createdAt, 'YYYY-MM-DD hh:mm')}>
                                {relativeTime(usage.createdAt)}
                            </td>
                            <td>{usage.actionOrEventName}</td>
                            <td>{usage.serviceApiKey.organization?.name || '-'}</td>
                            <td>{usage.userId || 'N/A'}</td>
                            <td>{usage.objectId || 'N/A'}</td>
                            <td><code>{formatCurrency(usage.cost)}</code></td>
                            <td>
                                <pre>
                                    <code className="break-all">
                                        {usage.data ? JSON.stringify(usage.data, null, 2) : 'N/A'}
                                    </code>
                                </pre>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={7}>
                            <div className="flex justify-center">
                                <Pagination
                                    count={usageTrackings?.count || 1}
                                    itemsPerPage={LIMIT}
                                    pageRangeDisplayed={3}
                                    forcePage={pageIndex}
                                    setPageIndex={setPageIndex} />
                            </div>
                        </td>
                    </tr>
                    {/* <tr>
                        <td colSpan={7}>
                            <div className="">
                                <pre>
                                    <code>
                                        {JSON.stringify({ pageIndex, usageTrackings }, null, 2)}
                                    </code>
                                </pre>
                            </div>
                        </td>
                    </tr> */}
                </tfoot>
            </table>
        
        </div>
        
    )
    
}

export default ServiceUsageTracking
