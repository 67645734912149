import { useState, useMemo, useEffect, useCallback } from 'react'
import { useWireState } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'

const useServicesViewModel = () => {
    
    const [tab, setTab] = useState('/dashboard/services')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [unmaskedKeys, setUnmaskedKeys] = useState({})
    const [apiKeysQuery, setApiKeysQuery] = useState('')
    const [deletingKey, setDeletingKey] = useState(false)
    
    const [serviceApiKeys, setServiceApiKeys] = useWireState(store.serviceApiKeys)
    const [
        modalCreateServiceApiKeyOpen,
        setModalCreateServiceApiKeyOpen,
    ] = useWireState(store.modalCreateServiceApiKeyOpen)
    
    // @todo this is client only, but eventually we'll need to fetch this from the server
    const filteredApiKeys = useMemo(() => {
        
        if (!apiKeysQuery?.length) return serviceApiKeys
        
        return serviceApiKeys.filter(it => (
            it.key.toLowerCase().includes(apiKeysQuery) ||
            it.name.toLowerCase().includes(apiKeysQuery) ||
            it.organization?.name.toLowerCase().includes(apiKeysQuery)
        ))
        
    }, [apiKeysQuery, serviceApiKeys])
    
    const onToggleMask = useCallback(id => {
        
        setUnmaskedKeys(prev => {
            
            const next = { ...prev }
            
            if (next[id])
                delete next[id]
            else
                next[id] = true
            
            return next
            
        })
        
    }, [])
    
    const onToggleEnabledClick = useCallback(async id => {
        
        const item = serviceApiKeys.find(it => it.id === id)
        
        if (!item)
            throw new Error('Invalid service API key ID')
        
        const value = item.enabled
        
        try {
            
            setServiceApiKeys(prev => prev.map(it =>
                it.id === id ? { ...it, enabled: !it.enabled } : it))
            
            const res = await actions.updateServiceApiKey(id, { enabled: !value })
            
            if (res)
                setServiceApiKeys(prev => prev.map(it =>
                    it.id === id ? { ...it, enabled: res.enabled } : it))
            
            return res
            
        } catch (e) {
            
            console.error('onToggleEnabledClick', e)
            
            setServiceApiKeys(prev => prev.map(it => [
                it.id === id ? { ...it, enabled: value } : it,
            ]))
            
        }
        
    }, [serviceApiKeys])
    
    const onDeleteClick = useCallback(async id => {
        
        setDeletingKey(id)
        
        try {
            
            await actions.deleteServiceApiKey(id)
            
            setServiceApiKeys(prev => prev.filter(it => it.id !== id))
            
        } catch (e) {
            
            console.error('onDeleteClick', e)
            
        }
        
        setDeletingKey(null)
        
    }, [])
    
    useEffect(() => {
        
        if (modalCreateServiceApiKeyOpen) return
        
        setLoading(true)
        
        actions.fetchServiceApiKeys()
            .then(setServiceApiKeys)
            .catch(e => setError(e?.message ?? 'Unknown error'))
            .finally(() => setLoading(false))
        
    }, [modalCreateServiceApiKeyOpen])
    
    return {
        
        // State
        tab,
        setTab,
        error,
        loading,
        apiKeysQuery,
        deletingKey,
        
        // Global State
        serviceApiKeys,
        modalCreateServiceApiKeyOpen,
        
        // Memos
        filteredApiKeys,
        unmaskedKeys,
        
        // Methods
        setApiKeysQuery,
        onToggleMask,
        onToggleEnabledClick,
        onDeleteClick,
        setModalCreateServiceApiKeyOpen,
        
    }
    
}

export default useServicesViewModel
