import { Suspense, lazy, memo, useMemo } from 'react'
import { RouterProvider, createBrowserRouter, createRoutesFromChildren } from 'react-router-dom'
import { useWireValue } from '@forminator/react-wire'
import { Route, Navigate } from 'react-router-dom'
import { user as storeUser } from '@store'
import { ably as storeAbly } from '@store/realtime'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import routes from '@/routes'
import CircleSpinner from '@components/shared/CircleSpinner'
import { debugRouteFailure, renderRoute, showErrorForRouteFailures } from './App.lib'
import { ROLE_ANNOTATOR_USER, ROLE_CHECK_MANAGER } from '@constants/roles'
import config from '@/config'
import AppContent from './AppContent'
import { AblyProvider } from 'ably/react'
import useUserHasRoles from '@/hook/useUserHasRoles'

const queryClient = new QueryClient()

/**
 * Main app entrypoint
 */
const Main = () => {
    
    const user = useWireValue(storeUser)
    const ably = useWireValue(storeAbly, null)
    const isCheckManager = useUserHasRoles(ROLE_CHECK_MANAGER)
    
    const token = localStorage.getItem('jwt')
    const isAnnotator = useMemo(() => (
        user?.roles?.some(it => it.name === ROLE_ANNOTATOR_USER) || false
    ), [user])
    
    const isOnlyCheckManager = useMemo(() => {
        return isCheckManager && user.roles.length === 1
    }, [isCheckManager, user?.roles])
    
    // Attempt to redirect user to the app if they're already
    // authenticated, otherwise default back to the sign-in screen
    const redirectUrl = useMemo(() => (
        token?.length > 0
            ? isAnnotator
                ? '/partners'
                : isOnlyCheckManager ? '/checks' : '/collections'
            : '/signin'
    ), [token, isAnnotator, isOnlyCheckManager])
    
    if (config.enableDataRouter) {
        
        const router = createBrowserRouter(
            createRoutesFromChildren([...routes.map(renderRoute), showErrorForRouteFailures
                ? debugRouteFailure
                : <Route path="*" element={<Navigate to={redirectUrl} />} />]),
        )
        
        return (
            <Suspense fallback={<CircleSpinner className="absolute-centered" />}>
                <RouterProvider router={router} fallbackElement={<CircleSpinner className="absolute-centered" />} />
            </Suspense>
        )
        
    } else {
        
        const App = lazy(() => import('./AppRoutes'))
        
        // @todo @featureflag
        const component = (
            <Suspense fallback={<CircleSpinner className="absolute-centered" />}>
                <QueryClientProvider client={queryClient}>
                    <App>
                        <AppContent />
                    </App>
                </QueryClientProvider>
            </Suspense>
        )
        
        if (ably)
            return (
                <AblyProvider client={ably}>
                    {component}
                </AblyProvider>
            )
        
        return component
        
    }
    
}

export default memo(Main)
