import { useState } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import buildInfo from '@/build.json'
import dayjs from 'dayjs'
import cn from 'classnames'

import SidebarPrimaryNav from '$components/Sidebar/SidebarPrimaryNav'
import SidebarQuickSettings from '$components/Sidebar/SidebarQuickSettings'
import {
    MdMenu,
    MdMenuOpen,
    MdKeyboardDoubleArrowLeft,
    MdKeyboardDoubleArrowRight, MdSettings,
} from 'react-icons/md'

import './Sidebar.css'

// @todo was having some issues with this & don't have time rn
const MENU_TOGGLE_ENABLED = false

const Sidebar = ({
    collapsed,
    setCollapsed,
}) => {
    
    const [open, setOpen] = useState(false)
    const [quickSettingsOpen, setQuickSettingsOpen] = useState(false)
    
    const user = useWireValue(store.user)
    
    const toggleQuickSettings = () => setQuickSettingsOpen(prev => !prev)
    
    return (
        
        <aside className={cn('Sidebar', { open, collapsed })}>
            
            {MENU_TOGGLE_ENABLED && (
                <button
                    className="p-3"
                    onClick={() => setOpen(!open)}>
                    <MdMenu className="text-2xl lg:hidden" />
                    <MdMenuOpen className="hidden text-2xl lg:block" />
                </button>
            )}
            
            <div className="content">
                
                {quickSettingsOpen
                    ? <SidebarQuickSettings toggle={toggleQuickSettings} />
                    : <SidebarPrimaryNav collapsed={collapsed} />}
                
                <div className="h-full grow" />
                
                <footer className="account-info">
                    <div className="text-sm">{user?.email}</div>
                    <button className="btn btn-ghost" onClick={toggleQuickSettings}>
                        <MdSettings className="text-lg"/>
                    </button>
                </footer>
                
                <footer className="build-info">
                    <div>Build {dayjs(buildInfo?.date)
                        ?.format('MMMM DD, YYYY [at] hh:mm A') ?? 'UNKNOWN'}</div>
                    <div>{buildInfo?.phrase?.toUpperCase() ?? '-'}</div>
                </footer>
            
            </div>
            
            <div className={cn('collapse-button', {
                'justify-end': !collapsed,
                'justify-center': collapsed,
            })}>
                <button className="" onClick={() => setCollapsed(!collapsed)}>
                    {collapsed
                        ? <MdKeyboardDoubleArrowRight className="text-2xl opacity-60" />
                        : <MdKeyboardDoubleArrowLeft className="text-2xl opacity-60" />}
                </button>
            </div>
        
        </aside>
        
    )
    
}

export default Sidebar
