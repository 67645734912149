import Logger from '@utils/log'
import ParseAPI from '@api/ParseAPI'
import * as store from '@store'
import { ATR_TRANSACTION_COMPLETED, ATR_VALUE_EXTRACTION_COMPLETED } from '@/constants'

const log = new Logger('actions/atr')

export const getPackets = async () => {
    
    try {
        
        const res = await ParseAPI.get('atr')
        
        store.atrPackets.setValue(res.data)
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const atrStatuses = async packetIds => {
    
    try {
        
        const res = await ParseAPI.post('atr/status', {
            ids: packetIds,
        })
        
        const pkts = res.data
        
        const packets = store.atrPackets.getValue()
        
        const newPackets = packets.map(p => {
            const foundPkt = pkts.find(pkt => pkt.id === p.id)
            
            if (foundPkt) {
                
                if ([ATR_VALUE_EXTRACTION_COMPLETED, ATR_TRANSACTION_COMPLETED].includes(foundPkt.status)) {
                    return null
                }
                
                return { ...p, ...foundPkt }
            }
            
            return p
        }).filter(np => !!np)
        
        store.atrPackets.setValue(newPackets)
        
        return res.data
        
    } catch (error) {
        log.e(error)
        throw error
    }
    
}

export const getFileList = async () => {
    
    try {
        
        const res = await ParseAPI.get('atr/folders')
        
        store.atrDirectories.setValue(res.data)
        
        return res.data
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const processAtrFile = async packetId => {
    
    try {
        
        const res = await ParseAPI.post('atr', { packetId })
        
        const packets = store.atrPackets.getValue()
        
        const idx = packets.findIndex(pkt => pkt.id === res.data.id)
        
        packets.splice(idx, 1, res.data)
        
        store.atrPackets.setValue([ ...packets ])
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const extractAtrDocuments = async directory => {
    
    try {
        
        const res = await ParseAPI.post('atr/extract', directory)
        
        const packets = store.atrPackets.getValue()
        
        store.atrPackets.setValue([ res.data, ...packets ])
        
    } catch (error) {
        log.e(error)
        throw error
    }
}